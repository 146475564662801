import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../Layout';
import Meta from '../basics/Meta';

import Header from '../basics/header';
import SubHeader from '../basics/sub-header';
import Blogs from '../basics/blogs';
import BorderColumns from '../basics/border-columns';
import ClientWin from '../basics/client-win';
import Spacer from '../basics/spacer';
import Contact from '../basics/contact';
import MobileDev from '../basics/mobile-dev';
import Stats from '../basics/stats';

const ServiceTemplate = ({ data }) => {
  const pageData = data.mobileAppsPageHJson;
  const siteURL = data.site.siteMetadata.siteUrl;
  return (
    <Layout useClass="services-layout">
      <Meta
        title={pageData.Meta.title}
        desc={pageData.Meta.desc}
        ogTitle={pageData.Meta.ogTitle}
        ogDesc={pageData.Meta.ogDesc}
        ogImg={siteURL + pageData.Meta.ogImg.publicURL}
      />
      <Header
        title={pageData.Header.title}
        subTitle={pageData.Header.subTitle}
        bgImage={pageData.Header.bgImage}
        hexLettersImage={pageData.Header.hexLettersImage.publicURL}
      />
      <SubHeader html={pageData.SubHeader.html} />
      <MobileDev />
      <BorderColumns col="3" title={pageData.BorderColumns.title} data={pageData.BorderColumns.data} />
      <Spacer />
      <ClientWin
        title={pageData.ClientWin.title}
        html={pageData.ClientWin.html}
        bgImg={pageData.ClientWin.bgImg.publicURL}
        hideResultsText={true}
        hideStats={false}
      />
      <Stats />
      <Contact title={pageData.Contact.title} text={pageData.Contact.text} />
      <Blogs title={pageData.Blogs.title} blogPosts={data.allWpPost.edges} />
    </Layout>
  );
};
export default ServiceTemplate;

export const pageQuery = graphql`
  query ($catSlug: String!) {
    allWpPost(
      limit: 3
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $catSlug } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 360, height: 156, transformOptions: { cropFocus: CENTER })
                }
              }
            }
          }
        }
      }
    }

    mobileAppsPageHJson {
      Meta {
        title
        desc
        ogTitle
        ogDesc
        ogImg {
          publicURL
        }
      }
      Blogs {
        title
      }
      SubHeader {
        html
      }
      ClientWin {
        html
        title
        bgImg {
          publicURL
        }
      }
      BorderColumns {
        title
        data {
          html
          title
        }
      }
      Contact {
        text
        title
      }
      Header {
        subTitle
        title
        bgImage {
          childImageSharp {
            gatsbyImageData(
              breakpoints: [500, 750, 1440, 1920, 2400]
              layout: FULL_WIDTH
              quality: 80
              placeholder: NONE
            )
          }
        }
        hexLettersImage {
          publicURL
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
